
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import Container2fa from '@/components/views/auth/2fa/Container2fa.vue'
import CodeConfirmation from '@/components/pages/auth/CodeConfirmation.vue'
import TmCountdown from '@/components/shared/TmCountdown.vue'
import TmAlert from '@/components/shared/TmAlert.vue'

export default defineComponent({
  components: {
    TmAlert,
    TmCountdown,
    CodeConfirmation,
    TmButton,
    Container2fa,
  },
  setup() {
    const code = ref('')
    const isLoading = ref(false)
    const isError = ref(false)

    const onCodeEntered = () => {
      isLoading.value = true

      setTimeout(() => {
        isLoading.value = false
        isError.value = true
      }, 3000)
    }

    return {
      code,
      isLoading,
      isError,
      onCodeEntered,
    }
  },
})
